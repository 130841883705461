<template>
  <div class="chart-bg">
    <StatPanel
      :column="statColumn"
      :data="stat"
      :isLoading="statLoading || isLoading"
    />
    <b-container>
      <b-row class="mt-3 chart-gap">
        <b-col
          cols="12"
          :lg="chart.class"
          v-for="chart in chartList"
          :key="chart.name"
          :class="[{ 'd-none': !chart.isChart }]"
        >
          <b-card
            class="shadow-sm border-none text-black chart-rounded"
            v-if="chart.isChart"
          >
            <div>
              <div>
                <div class="content-between">
                  <div :class="['chart-title-name']">
                    {{ chart.name }}
                  </div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="isLoading || chart.isFetch">
                <ChartLoading
                  :id="'loading-' + chart.name"
                  :type="chart.type"
                />
              </template>
              <template v-else-if="chart.type == 'bar'">
                <RedemtionRate
                  :id="chart.name"
                  title="Customer Point"
                  :chartRange="chart.data"
                  titleLeft="Number of Persons"
                  titleBottom="Customer Point"
                />
              </template>
              <template v-else>
                <PieChart
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  class="mt-auto"
                  :label="chart.label"
                  :type="chart.type"
                  :filterValue="chart.filterValue"
                  :xText="chart.xText"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="mb-2 content-between">
              <div class="chart-title-name"></div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[6].filter"
                  :key="chartFilter + '-' + chartList[6].name"
                  @click="handleChartFilter(chartFilter, chartList[6])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.customer"
              :items="customer.items"
              :busy="customer.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="customerSorting"
              :sort-by.sync="customer.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="customer.filter.sortDesc"
            >
              <template v-slot:cell(customer_name)="data">
                <b-row>
                  <b-col>
                    <router-link
                      :to="`/report/customer/${data.item.user_guid}`"
                    >
                      <u> {{ data.item.customer_name }}</u>
                    </router-link>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(telephone)="data">
                <div>{{ convertTelePhone(data.item.telephone) }}</div>
              </template>
              <template v-slot:cell(member_tier)="data">
                <b-row>
                  <b-col class="text-right p-0" cols="3">
                    <img
                      :src="data.item.icon"
                      class="icon-level"
                      @error="handleImageSrc"
                    />
                  </b-col>
                  <b-col class="text-left" cols="9">
                    {{ data.item.member_tier }}
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(email)="data">
                <div class="text-left">
                  {{ data.item.email ? data.item.email : "-" }}
                </div>
              </template>
              <template v-slot:cell(total_point)="data">
                <div>{{ data.item.total_point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(total_spend)="data">
                <div>{{ data.item.total_spend | numeral("0,0.00") }}</div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.created_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(birthday)="data">
                <div>{{ data.item.birthday | moment($formatDateNew) }}</div>
              </template>
              <template v-slot:foot()="data">
                <span v-if="data.field.key == 'total_spend'">
                  {{
                    stat.total_spend_customer_table | numeral("0,0.00")
                  }}</span
                >

                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeCustomer"
              @pagination="paginationCustomer"
              :filter="customer.filter"
              :rows="customer.rows"
              class="p-2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="mb-2 content-between">
              <div class="chart-title-name">Product</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  name="search-product"
                  class="search-bar mb-2"
                  @keyup.enter="handleSearchProduct"
                  placeholder="Search Name, Barcode"
                  v-model="product.filter.search"
                  style="width: 300px"
                >
                </b-form-input>
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[7].filter"
                  :key="chartFilter + '-' + chartList[7].name"
                  @click="handleChartFilter(chartFilter, chartList[7])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="product.items"
              :busy="product.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="product.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="product.filter.sortDesc"
            >
              <template v-slot:cell(totalCustomer)="data">
                <span>
                  {{ data.item.totalCustomer | numeral("0,0") }}
                  ({{
                    ((data.item.totalCustomer /
                      stat.total_product_table["totalCustomer"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalBill)="data">
                <span>
                  {{ data.item.totalBill | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(percenSalesContribution)="data">
                <span>
                  {{
                    ((data.item.totalSales /
                      stat.total_product_table["totalSales"]) *
                      100)
                      | numeral("0.00")
                  }}%
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_product_table[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'totalCustomer' ||
                      data.field.key == 'totalQuantity' ||
                      data.field.key == 'totalBill' ||
                      data.field.key == 'newMembers'
                    "
                  >
                    {{
                      stat.total_product_table[data.field.key] | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      stat.total_product_table[data.field.key]
                        | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div>
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="product.filter"
              :rows="product.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <SideBarFilter
      id="filter"
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Transaction Date"
          id="1"
          :from="filter.TransactionDateFrom"
          :to="filter.TransactionDateTo"
          :type="filter.LastType"
          @update:type="(value) => settingFilterType(value, 'LastType')"
          @update:from="
            (value) => settingFilterType(value, 'TransactionDateFrom')
          "
          @update:to="(value) => settingFilterType(value, 'TransactionDateTo')"
        />

        <div class="mb-3">
          <MultiSelect
            :isLoading="!isFilterProduct"
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Barcode / Product"
            v-model="filter.ProductFilter"
            :value="filter.ProductFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="branch"
            textFloat="Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="AH1"
            :options="options.ah_1"
            textField="name"
            valueField="id"
            placeholder="Select AH1"
            v-model="filter.ah_1"
            :value="filter.ah_1"
            @remove="(val) => removeAH(val, 1)"
            @select="(val) => changeAH(val, 1)"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="AH2"
            :options="options.ah_2"
            textField="name"
            valueField="id"
            placeholder="Select AH2"
            v-model="filter.ah_2"
            :value="filter.ah_2"
            @remove="(val) => removeAH(val, 2)"
            @select="(val) => changeAH(val, 2)"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="AH3"
            :options="options.ah_3"
            textField="name"
            valueField="id"
            placeholder="Select AH3"
            v-model="filter.ah_3"
            :value="filter.ah_3"
            @remove="(val) => removeAH(val, 3)"
            @select="(val) => changeAH(val, 3)"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="AH4"
            :options="options.ah_4"
            textField="name"
            valueField="id"
            placeholder="Select AH4"
            v-model="filter.ah_4"
            :value="filter.ah_4"
            @select="(val) => changeAH(val, 4)"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Birth Month"
            :options="birthMonth"
            textField="text"
            valueField="value"
            placeholder="Select Birth Month"
            v-model="filter.birth_month"
            :value="filter.birth_month"
          />
        </div>
        <div class="my-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="
        (val) => {
          filterExportFile.email = val;
        }
      "
      @submit="exportFile(filterExportFile.mode, filterExportFile.isRe)"
    />
  </div>
</template>

<script>
import moment from "moment";
import PieChart from "./PieChart";
import ChartLoading from "@/components/loading/ChartLoading";
import Multiselect from "vue-multiselect";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import RedemtionRate from "chart/RedemtionRate";
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,
    PieChart,
    ChartLoading,
    InputDatePickerFilter,
    ModalInputEmail,
    RedemtionRate,
  },
  data() {
    return {
      isFilterLoading: true,
      filterExportFile: {
        email: "",
        mode: "",
      },
      filter: {
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,
        AgeGroupFilter: this.$store.state.filter.age,
        GenderFilter: this.$store.state.filter.gender,
        ProductFilter: this.$store.state.filter.product,
        MemberTierFilter: this.$store.state.filter.tier,

        TransactionDateFrom: this.$store.state.filter.from,
        TransactionDateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,

        LastType: this.$store.state.filter.dateType,
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
        birth_month: [],
      },

      options: {
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
        memberTiers: [],
        ahAll: {
          ah_1: [],
          ah_2: [],
          ah_3: [],
          ah_4: [],
        },
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        product: [],
      },
      chartList: [
        {},
        {
          name: "Member By Member Tier",
          filterValue: "",
          text: "text",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getMemberTier(),
          type: "pie",
          isFetch: false,
          exportMode: 1,
          isChart: true,
          xText: "xText",
          class: "4",
        },

        {
          name: "Revenue By Member Tier",
          filterValue: "",
          text: "text",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getMemberTier(),
          type: "pie",
          isFetch: false,
          exportMode: 2,
          isChart: true,
          xText: "xText",
          class: "4",
        },
        {
          name: "Revenue By Branch Group",
          filterValue: "",
          text: "text",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getRevenueByBranchGroup(),
          type: "pie",
          isFetch: false,
          exportMode: 3,
          isChart: true,
          xText: "xText",
          class: "4",
        },
        {
          name: "Customer Point",
          filterValue: "",
          text: "text",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getRevenueByRedeem(),
          type: "bar",
          isFetch: false,
          exportMode: 6,
          isChart: true,
          xText: "xText",
          class: "6",
        },
        {
          name: "Consent Status",
          filterValue: "",
          text: "Number Of Customer",
          filter: ["Export"],
          label: [],
          data: [],
          get: () => this.getConsent(),
          type: "pie",
          isFetch: false,
          exportMode: 4,
          isChart: true,
          xText: "xText",
          class: "6",
        },

        {
          name: "Customer",
          filter: ["Export"],
          exportMode: 5,
        },
        {
          name: "Product",
          filter: ["Export"],
          exportMode: 8,
        },
      ],
      fields: {
        customer: [
          {
            key: "customer_name",
            label: "Customer Name",
            sortable: true,
            class: "w-2",
            text: "Total",
          },
          {
            key: "member_tier",
            label: "Tier",
            // sortable: true,
            class: "w-1",
          },
          {
            key: "telephone",
            label: "Telephone",
            class: "w-1",
          },
          {
            key: "email",
            label: "Email",
            class: "w-1",
          },
          {
            key: "total_point",
            label: "Total Point",
            // sortable: true,
            class: "w-1",
          },
          {
            key: "created_time",
            label: "Register Date",
            class: "w-1",
          },
          {
            key: "total_spend",
            label: "Total Spend",
            sortable: true,
            class: "w-1",
          },
          {
            key: "birthday",
            label: "Birthday",
            class: "w-1",
          },
        ],
        product: [
          {
            key: "barcode",
            label: "Barcode",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "productName",
            label: "Name",
            thClass: "w-3",
            sortable: true,
          },
          {
            key: "totalCustomer",
            label: "% Customer",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalSales",
            label: "Total Sales",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalQuantity",
            label: "Total Quantity",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalBill",
            label: "Total Bill",
            class: "w-2",
            sortable: true,
          },
          {
            key: "atv",
            label: "ATV",
            class: "w-2",
            sortable: true,
          },
          {
            key: "percenSalesContribution",
            label: "% Sales Contribution",
            class: "w-2",
            sortable: true,
          },
        ],
      },
      product: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      customer: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        new_member: 0,
        member: 0,
        percent_new_member: 0,
        totalBill: 0,
        totalRevenue: 0,
        totalUnit: 0,
        atv: 0,
        upt: 0,
        total_customer_table: {},
        total_product_table: {},
      },
      statColumn: [
        {
          name: "Total Bill",
          key: "totalBill",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Sales",
          key: "totalRevenue",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "Total Unit",
          key: "totalUnit",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "ATV",
          key: "atv",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "UPT",
          key: "upt",
          isPercent: false,
          isDecimal: true,
        },
      ],
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
        TransactionDateFrom: "",
        TransactionDateTo: "",
      },
      statLoading: true,
      birthMonth: [],
      colorList: [],
      isLoading: true,
    };
  },
  computed: {
    ah_1_key() {
      return this.filter[`ah_1`].map(({ key }) => key) || [];
    },
    ah_2_key() {
      return this.filter[`ah_2`].map(({ key }) => key) || [];
    },
    ah_3_key() {
      return this.filter[`ah_3`].map(({ key }) => key) || [];
    },
    ah_4_key() {
      return this.filter[`ah_4`].map(({ key }) => key) || [];
    },
    ah_key() {
      return [
        ...this.ah_1_key,
        ...this.ah_2_key,
        ...this.ah_3_key,
        ...this.ah_4_key,
      ];
    },
  },
  async mounted() {
    for (let index = 0; index < 50; index++) {
      this.colorList.push(getRandomColor());
    }
    this.getProductFilter();
    // await this.initDateFilter();
    this.getFilter();
    this.getAH();
    this.getDefault();
    let i = 1;
    for (const month of this.$moment.months()) {
      this.birthMonth.push({ text: month, value: i });
      i++;
    }
  },
  methods: {
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      this.initFilterDate.TransactionDateFrom = moment(toDay).day(-7).format();
      this.initFilterDate.TransactionDateTo = moment(toDay).format();
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
      this.filter.LastType = 0;
      this.filter.TransactionDateFrom = this.initFilterDate.TransactionDateFrom;
      this.filter.TransactionDateTo = this.initFilterDate.TransactionDateTo;
    },
    exportModal(mode) {
      this.filterExportFile.mode = mode;
      this.$refs.ModalInputEmail.show();
    },

    async getDefault() {
      this.isLoading = true;
      this.getCustomer();
      this.getProduct();
      this.statLoading = true;
      this.chartList[2].isFetch = true;
      this.chartList[1].isFetch = true;
      this.chartList[3].isFetch = true;
      this.chartList[4].isFetch = true;
      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/summary/default`,
        this.filter
      );
      await this.getStat(true, res.data.detail.result_summary);
      this.getMemberTier(true, res.data.detail.result_membertier);
      this.getRevenueByBranchGroup(true, res.data.detail.result_branch_group);
      this.getRevenueByRedeem(true, res.data.detail.result_redeem);
      this.getConsent(true, res.data.detail.result_consent);
      this.isLoading = false;
    },
    async getColor(array) {
      let color = [];
      for (const items in array) {
        let getColor = await this.$store.dispatch("getChartColor", items);
        color.push(getColor);
      }
      return color;
    },

    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).subtract(1, "years").format("YYYY");
      else if (type === 2)
        value = moment(date, "MM/YYYY").subtract(1, "months").format("MM/YYYY");
      else
        value = moment(date, "DD/MM/YYYY")
          .subtract(1, "days")
          .format("DD/MM/YYYY");

      return value;
    },
    async getMemberTier(has, data) {
      this.chartList[2].isFetch = true;
      this.chartList[1].isFetch = true;
      let body = {
        ...this.filter,
      };
      var respData = null;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/summary/member_tier_graph`,
          body
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }
      let dataSet, label, dataSet2, label2;

      if (respData) {
        dataSet = [
          {
            label: "Member By Member Tier",
            data: respData.number_member.map((el) => el.percent_data),
            dataRaw: respData.number_member,
            backgroundColor: await this.getColor(respData.number_member),
          },
        ];

        label = respData.number_member.map((el) => el.group);

        dataSet2 = [
          {
            label: "Revenue By Member Tier",
            data: respData.revnue_member.map((el) => el.percent_data),
            dataRaw: respData.revnue_member,
            backgroundColor: await this.getColor(respData.revnue_member),
          },
        ];

        label2 = respData.revnue_member.map((el) => el.group);
      }
      this.chartList[2].label = respData.revnue_member.map((el) => el.group);
      this.chartList[2].data = dataSet2 || [];
      this.chartList[1].label = respData.number_member.map((el) => el.group);
      this.chartList[1].data = dataSet || [];

      this.chartList[2].isFetch = false;
      this.chartList[1].isFetch = false;
    },

    async getRevenueByBranchGroup(has, data) {
      this.chartList[3].isFetch = true;
      var respData = data;

      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/summary/revenue_branch_group`,
          this.filter
        );
        respData = res.data.detail;
      }

      var dataSet = [
        {
          label: "Revenue By Branch Group",
          data: respData.map((el) => el.percent_data),
          dataRaw: respData,
          backgroundColor: await this.getColor(respData),
        },
      ];
      let label = respData.map((el) => el.group);

      this.chartList[3].label = label;
      this.chartList[3].data = dataSet;
      this.chartList[3].isFetch = false;
    },
    async getRevenueByRedeem(has, data) {
      this.chartList[4].isFetch = true;
      var respData = data;
      let label = data.map((el) => {
        if (el.upper_bound) {
          return `${this.addCommas(el.lower_bound)} - ${this.addCommas(
            el.upper_bound
          )}`;
        } else {
          return `${this.addCommas(el.lower_bound)} and more`;
        }
      });

      // this.chartList[4].label = label;
      this.chartList[4].data = {
        data: {
          label: "Revenue By Redeem Customer",
          data: data.map((el, index) => el.percent),
          dataRaw: data.map((el, index) => el.num_data),
          backgroundColor: await this.getColor(label),
        },
        accumulate: {
          percent: data.map((el, index) => el.percent_accumulate),
          num: data.map((el, index) => el.accumulate),
        },
        label: label,
        $percent: data.map((el, index) => el.percent),
      };
      console.log(this.chartList[4].data);
      this.chartList[4].isFetch = false;
    },
    async getConsent(has, data) {
      this.chartList[5].isFetch = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(
          `/DashBoard/summary/consent_status`,
          this.filter
        );
        respData = res.data.detail;
      }
      let dataSet = [
        {
          label: "Consent Status",
          data: respData.map((el) => el.percent_data),
          dataRaw: respData,
          backgroundColor: await this.getColor(respData),
        },
      ];

      let label = respData.map((el) => el.group);

      this.chartList[5].label = label;
      this.chartList[5].data = dataSet;
      this.chartList[5].isFetch = false;
    },
    async getStat(has, data) {
      this.statLoading = true;
      var respData = data;
      if (!has) {
        const res = await this.$report.post(`/DashBoard/summary`, this.filter);
        respData = res.data.detail;
      }
      this.stat = respData;

      this.statLoading = false;
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.TransactionDateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.TransactionDateTo).format(
              "DD MMM YYYY"
            )}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", this.stat, date);
    },
    async getProduct() {
      this.product.isBusy = true;
      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/summary/product`,
        {
          customFilterRequest: {
            ...this.filter,
          },
          SearchProduct: this.product.filter.search,
          PageNumber: this.product.filter.page,
          RecordPerPage: this.product.filter.take,
          SortColumnId: this.product.filter.SortColumnId,
          SortType: this.product.filter.SortType,
        }
      );
      if (res.data.detail) {
        this.product.items = res.data.detail.revenueProductResponses;
        this.product.rows = res.data.detail.totalRecord;
      } else {
        this.product.items = [];
        this.product.rows = 0;
      }

      this.product.isBusy = false;
    },
    async getCustomer() {
      this.customer.isBusy = true;
      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/summary/customer`,
        {
          customFilterRequest: {
            ...this.filter,
          },
          // SearchCustomer: this.customer.filter.search,
          PageNumber: this.customer.filter.page,
          RecordPerPage: this.customer.filter.take,
          SortColumnId: this.customer.filter.SortColumnId,
          SortType: this.customer.filter.SortType,
        }
      );
      if (res.data.detail) {
        this.customer.items = res.data.detail.data;
        this.customer.rows = res.data.detail.total;
      } else {
        this.customer.items = [];
        this.customer.rows = 0;
      }
      this.customer.isBusy = false;
    },
    paginationProduct(val) {
      this.product.filter.page = val;
      this.getProduct();
    },
    handleChangeTakeProduct(val) {
      this.product.filter.take = val;
      this.product.filter.page = 1;
      this.getProduct();
    },
    paginationCustomer(val) {
      this.customer.filter.page = val;
      this.getCustomer();
    },
    handleChangeTakeCustomer(val) {
      this.customer.filter.take = val;
      this.customer.filter.page = 1;
      this.getCustomer();
    },
    async getProductFilter() {
      const res = await this.axios.post(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 10,
        }
      );
      let products = res.data.productFilterModels.map((el) => {
        return { id: el.id, productName: `${el.barcode} / ${el.productName}` };
      });
      this.options.product = products;
      this.isFilterProduct = true;
    },
    async removeAH(selected, number) {
      let maxIndex = number + 1;
      for (let index = 1; index < 4; index++) {
        if (this.filter[`ah_${index}`].length) {
          maxIndex = index;
        }
      }
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        this.filter[`ah_${nextIndex}`] = await this.filter[
          `ah_${nextIndex}`
        ].filter((item) => item[`ah_${number}_ahid`] != selected.id);
        if (this.filter[`ah_${index}`].length == 0) {
          maxIndex = index;
        }
        this.options[`ah_${nextIndex}`] = await this.handleListAH(
          nextIndex,
          maxIndex,
          this.ah_key
        );
      }
    },
    async changeAH(selected, number) {
      let maxIndex = number + 1;
      for (let index = number; index < 4; index++) {
        let nextIndex = index + 1;
        if (nextIndex <= 4)
          this.options[`ah_${nextIndex}`] = await this.handleListAH(
            nextIndex,
            maxIndex,
            this.ah_key
          );
      }
    },
    async handleListAH(nextIndex, maxIndex, lastKey) {
      let list = await this.ahAll[`ah_${nextIndex}`];
      if (lastKey.length) {
        list = await this.ahAll[`ah_${nextIndex}`].filter((item) => {
          let ah = "AH";
          for (let i = 1; i < maxIndex; i++) {
            ah += item[`ah_${i}_ahid`];
          }
          let result = lastKey.indexOf(ah);
          return result > -1;
        });
      }
      return list;
    },
    async getAH() {
      const res = await this.axios.get(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/summary/ah_filter`
      );

      this.ahAll = res.data.detail;
      this.options.ah_1 = this.ahAll.ah_1;
      this.options.ah_2 = this.ahAll.ah_2;
      this.options.ah_3 = this.ahAll.ah_3;
      this.options.ah_4 = this.ahAll.ah_4;
    },
    async getFilter() {
      const res = await this.axios(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomFilter`
      );

      this.options = {
        ...res.data,
        product: [...this.options.product],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async exportFile(mode) {
      let body = {
        ...this.filter,
      };
      let url = `${process.env.VUE_APP_REPORT_API}/Dashboard/summary/report/`;
      let fileName = "";
      if (mode == 5) {
        return this.$emit("export");
      } else if (mode == 1) {
        url += `member_tier`;
        fileName = "Member-MemberTier";
      } else if (mode == 2) {
        url += `member_tier_revenue`;
        fileName = "Revenue-MemberTier";
      } else if (mode == 3) {
        url += `branch_group_revenue`;
        fileName = "Revenue-BranchGroup";
      } else if (mode == 4) {
        url += `consent_status`;
        fileName = "Consent-Status";
      } else if (mode == 8) {
        url += `product`;
        fileName = "Summary Product";
      } else if (mode == 6) {
        url += `redeem_customer`;
        fileName = "Revenue-Redeem";
      }
      // if (mode == 6) {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(url, body, {
        responseType: "blob",
      });

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
      // }
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.exportFile(chart.exportMode);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;

      await chart.get();
    },
    clearFilter() {
      this.filter = {
        ChannelFilter: [],
        BranchFilter: [],
        AgeGroupFilter: [],
        GenderFilter: [],
        ProductFilter: [],
        MemberTierFilter: [],
        TransactionDateFrom: this.initFilterDate.TransactionDateFrom,
        TransactionDateTo: this.initFilterDate.TransactionDateTo,
        TypeDateFilter: 2,
        LastType: 2,
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      };

      this.filterActions();
      this.$refs.filter.hide();
      this.$store.dispatch("clearFilter");
    },
    filterActions() {
      if (this.filter.LastType == 1) {
        this.TypeDateFilter = 3;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 2) {
        this.TypeDateFilter = 2;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 3) {
        this.TypeDateFilter = 1;
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else {
        this.TypeDateFilter = 0;
        if (this.filter.TransactionDateFrom)
          this.filter.TransactionDateFrom = this.$moment(
            this.filter.TransactionDateFrom
          ).format("YYYY-MM-DD");
        if (this.filter.TransactionDateTo)
          this.filter.TransactionDateTo = this.$moment(
            this.filter.TransactionDateTo
          ).format("YYYY-MM-DD");
      }

      this.getDefault();
    },
    handleSearchProduct() {
      this.getProduct();
    },
    handleInput(val) {
      this.filter.PurchaseTo = val;
    },
    clearExportFilter() {
      this.filterExportFile = {
        email: "",
      };
    },
    sortingChanged(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fields.product.findIndex((el) => el.key == "totalSales");

        index = index + 1;
      } else {
        index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }

      if (
        this.product.filter.SortType &&
        !ctx.sortDesc &&
        this.product.filter.SortColumnId == index
      ) {
        this.product.filter.SortColumnId = 0;
        this.product.filter.sortBy = "";
        this.product.filter.sortDesc = "";
      } else {
        this.product.filter.SortColumnId = index;
        this.product.filter.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
    customerSorting(ctx) {
      let index = this.fields.customer.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;
      if (
        this.customer.filter.SortType &&
        !ctx.sortDesc &&
        this.customer.filter.SortColumnId == index
      ) {
        this.customer.filter.SortColumnId = 0;
        this.customer.filter.sortBy = "";
        this.customer.filter.sortDesc = "";
      } else {
        this.customer.filter.SortColumnId = index;
        this.customer.filter.SortType = ctx.sortDesc;
      }
      this.getCustomer();
    },
    settingFilterType(val, key) {
      this.filter[key] = val;
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}

.icon-level {
  height: auto;
  width: auto;
  max-width: 23px;
  max-height: 23px;
}
</style>
