<template>
  <div class="chart-bg">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Connect Date"
          id="7"
          :from="filter.DateFrom"
          :to="filter.DateTo"
          :type="filter.LastType"
          @update:type="(value) => (filter.LastType = value)"
          @update:from="(value) => (filter.DateFrom = value)"
          @update:to="(value) => (filter.DateTo = value)"
          :useStore="false"
        />
        <FilterCustomDate
          customText="Register Date"
          id="7"
          :from="filter.RegisterDateFrom"
          :to="filter.RegisterDateTo"
          :type="filter.LastTypeRegister"
          @update:type="(value) => (filter.LastTypeRegister = value)"
          @update:from="(value) => (filter.RegisterDateFrom = value)"
          @update:to="(value) => (filter.RegisterDateTo = value)"
          :useStore="false"
        />

        <div class="mb-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelConnectTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="gender"
            textFloat="Gender"
            :options="options.genderModels"
            textField="genderGroupName"
            valueField="id"
            placeholder="Select Gender"
            v-model="filter.GenderFilter"
            :value="filter.GenderFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            textFloat="Province"
            :options="options.Provinces"
            textField="name"
            valueField="name"
            placeholder="Select Province"
            v-model="filter.ProvinceFilter"
            :value="filter.ProvinceFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <b-container class="pt-3">
      <b-row class="chart-gap">
        <b-col cols="12" md="6">
          <b-card class="mb-0 h-100 shadow-sm border-none chart-rounded">
            <div class="chart-title-name mb-3">Profile Completeness</div>

            <b-progress
              :value="profileComplete.customer_completed"
              :max="profileComplete.total_customer"
              class="mb-3"
              :style="{
                '--display': `${
                  profileComplete.customer_completed > 0 ? 'flex' : 'none'
                }`,
              }"
            ></b-progress>

            <b-row class="align-items-center justify-content-between">
              <b-col md="6" class="text-primary font-bold">Overall</b-col>
              <b-col md="6" xl="4" class="text-right text-primary">
                <b-row no-gutters>
                  <b-col cols="6" class="text-right mx-auto">
                    {{ Math.floor(profileComplete.percent_complted) }}%
                  </b-col>
                  <b-col cols="6" class="text-right text-primary ml-auto">
                    <span
                      >({{
                        profileComplete.customer_completed | numeral("0,0")
                      }}/{{ profileComplete.total_customer | numeral("0,0") }})
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row
              class="row-gap-lg"
              style="max-height: 500px; overflow-y: auto"
            >
              <b-col cols="6" class="text-primary">Overall</b-col>
              <b-col
                cols="6"
                xl="4"
                :key="'field_value' + fields.field_name"
                class="ml-auto"
              >
                <b-row no-gutters>
                  <b-col cols="6" class="text-right ml-auto text-primary">
                    {{ Math.floor(profileComplete.percent_complted) }}%
                  </b-col>
                  <b-col cols="6" class="text-right text-primary ml-auto">
                    <span>
                      ({{
                        profileComplete.customer_completed | numeral("0,0")
                      }}/{{ profileComplete.total_customer | numeral("0,0") }})
                    </span>
                  </b-col>
                </b-row>
              </b-col>

              <template v-for="fields of profileComplete.profile_detail">
                <b-col cols="6" :key="'field_name' + fields.field_name">
                  {{ fields.field_name }}
                </b-col>
                <b-col
                  cols="6"
                  xl="4"
                  :key="'field_value' + fields.field_name"
                  class="ml-auto"
                >
                  <b-row no-gutters>
                    <b-col cols="6" class="text-right ml-auto">
                      {{ Math.floor(fields.percent_completed) }}%
                    </b-col>
                    <b-col cols="6" class="text-right ml-auto">
                      ({{ fields.customer_completed | numeral("0,0") }}/{{
                        profileComplete.total_customer | numeral("0,0")
                      }})
                    </b-col>
                  </b-row>
                </b-col>
              </template>
            </b-row>
          </b-card>
        </b-col>
        <template v-for="(chart, index) in chartList">
          <b-col
            cols="12"
            md="6"
            :key="index"
            class="resize"
            v-if="chart.isChart"
          >
            <b-card
              class="mb-0 h-100 shadow-sm border-none text-black chart-rounded"
            >
              <div>
                <div class="d-flex justify-content-between">
                  <div class="chart-title-name">{{ chart.name }}</div>
                  <div class="d-flex" style="column-gap: 5px">
                    <div
                      :class="`f-14 underline cursor-pointer`"
                      v-for="chartFilter in chart.filter"
                      :key="chartFilter + '-' + chart.name"
                      @click="handleChartFilter(chartFilter, chart)"
                    >
                      <span
                        :class="`${
                          chart.filterValue == chartFilter ||
                          chartFilter == 'Export'
                            ? 'text-highlight'
                            : 'text-gray'
                        }`"
                        >{{ chartFilter }}</span
                      >
                    </div>
                  </div>
                </div>
                <template v-if="chart.isFetch">
                  <ChartLoading :id="'loading-' + chart.name" type="line" />
                </template>
                <template v-else>
                  <BasketChart
                    :id="chart.name"
                    :chartData="chart.data"
                    :text="chart.text"
                    class="mt-auto"
                    :hideLegend="chart.hideLegend"
                    :label="chart.label"
                    :type="chart.type"
                    :filterValue="chart.filterValue"
                    :xText="chart.xText"
                    :isStacked="chart.isStacked"
                  />
                </template>
              </div>
            </b-card>
          </b-col>
        </template>

        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">{{ chartList[1].name }}</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[1].filter"
                  :key="chartFilter + '-' + chartList[1].name"
                  @click="handleChartFilter(chartFilter, chartList[1])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm">
              <b-table
                fixed
                striped
                hover
                :fields="fields.channel"
                :items="channel.items"
                :busy="channel.isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(total_member)="data">
                  <span>
                    {{ data.item.total_member | numeral("0,0") }}
                  </span>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="6">
          <b-card
            class="mb-0 h-100 shadow-sm border-none text-black chart-rounded"
          >
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Age</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportType(1, 'ReportAgeGroup')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <template v-if="ageLoading">
                <ChartLoading :id="'loading-' + 'Age'" type="line" />
              </template>
              <template v-else>
                <Chart
                  id="age"
                  :chartData="chartAge"
                  text="Percent Of Responses"
                  class="mt-auto"
                  :label="chartAgeLabel"
                  xText="Age Group"
                  type="bar"
                  :customTooltipTitle="customTitle"
                  :customTooltipLabel="customLabel"
                  usePercent
                />
              </template>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="6">
          <b-card
            class="mb-0 h-100 shadow-sm border-none text-black chart-rounded"
          >
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">Gender</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportType(2, 'ReportGenderGroup')"
                      >Export</span
                    >
                  </div>
                </div>
              </div>

              <template v-if="genderLoading">
                <ChartLoading :id="'loading-' + 'Gender'" type="line" />
              </template>
              <template v-else>
                <Chart
                  id="gender"
                  :chartData="chartGender"
                  text="Percent Of Responses"
                  class="mt-auto"
                  :label="chartGenderLabel"
                  xText="Gender"
                  type="bar"
                  :customTooltipTitle="customTitle"
                  :customTooltipLabel="customLabel"
                  usePercent
                />
              </template>
            </div>
          </b-card>
        </b-col>

        <b-col v-for="(item, index) in customFieldChart" :key="item.id" md="6">
          <b-card class="card-shadow border-none text-black chart-rounded">
            <div>
              <div class="content-between mb-2">
                <div class="chart-title-name">{{ item.name }}</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div :class="`f-14 underline cursor-pointer`">
                    <span
                      class="text-highlight"
                      @click="exportType(3, `Report${item.name}`, item.id)"
                      >Export</span
                    >
                  </div>
                </div>
              </div>
              <Chart
                :id="item.id.toString()"
                :chartData="item.charts"
                text="Percent Of Responses"
                class="mt-auto"
                :label="customFiedlLabel[index]"
                hideLegend
                type="bar"
                :customTooltipTitle="customTitle"
                :customTooltipLabel="customLabel"
                usePercent
              />
            </div>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card
            class="mb-0 h-100 shadow-sm border-none text-black chart-rounded"
            ><div class="d-flex justify-content-between mb-3">
              <span class="chart-title-name">Province</span>
              <span
                class="text-highlight f-14 underline cursor-pointer"
                @click="exportType(4, 'ReportProvince')"
                >Export</span
              >
            </div>
            <b-table
              responsive
              striped
              hover
              :fields="fieldsProvince"
              :items="itemsProvince"
              :busy="isProvinceBusy"
              show-empty
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="filter.sortBy"
              no-local-sorting
              :sort-desc.sync="filter.sortDesc"
              foot-clone
              no-footer-sorting
            >
              <template v-slot:cell(value)="data">
                <span>
                  {{ data.item.value | numeral("0,0") }} ({{
                    data.item.percent
                  }}%)
                </span>
              </template>
              <template v-slot:foot()="data">
                <span v-if="data.field.key == 'value'">
                  {{ subTotal | numeral("0,0") }}
                </span>
                <span v-else>
                  {{ data.field.text }}
                </span>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="filter"
              :rows="rows"
              :limit="2"
          /></b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import ChartLoading from "@/components/loading/ChartLoading";
import Chart from "chart";
import BasketChart from "chart/stack";
import Multiselect from "vue-multiselect";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : null;
}

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,
    Chart,
    ChartLoading,
    BasketChart,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        DateFrom: this.$store.state.filter.from,
        DateTo: this.$store.state.filter.to,
        RegisterDateFrom: "",
        RegisterDateTo: "",
        TypeDateFilter: 2, // 1 = Year , 2 = Month , 3 = Day
        LastType: this.$store.state.filter.dateType,
        LastTypeRegister: 3,
        ChannelFilter: this.$store.state.filter.channel, // list int
        MemberTierFilter: this.$store.state.filter.tier,
        AgeGroupFilter: [],
        GenderFilter: [],
        ProvinceFilter: [],
        take: 10,
        page: 1,
        sortDesc: "",
        sortBy: "",
        PageNumber: 1,
        RecordPerPage: 10,
        SortColumnId: 0,
        SortType: false, // true = ASC, flase = DESC
      },

      options: {
        memberTiers: [],
        channelConnectTypes: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        ageGroups: [],
        genderModels: [],
        Provinces: [],
      },
      chartList: [
        {
          name: "Connect Channel",
          filterValue: "Month",
          text: "Total Member",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "report/date",
          get: () => this.getConnectChannelChart(),
          type: "bar",
          isFetch: false,
          isChart: true,
        },
        {
          name: "Connect Channel",
          get: () => this.getConnectChannelList(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
        {
          name: "Total Connect Channel",
          get: () => this.getTotalCustomer(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
      ],
      fields: {
        channel: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
        totalCustomer: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
      },
      channel: {
        items: [],
        isBusy: true,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      totalCustomer: {
        items: [],
        isBusy: true,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {},
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
      profileComplete: {
        customer_completed: 0,
        total_customer: 0,
        percent_complted: 0,
        profile_detail: [],
      },
      chartAgeLabel: [],
      chartAge: [],
      ageLoading: true,
      chartGenderLabel: [],
      chartGender: [],
      genderLoading: true,
      customFieldChart: [],
      customFieldLoading: true,
      fieldsProvince: [
        {
          key: "group",
          label: "Province",
          sortable: true,
          text: "Total",
          class: "text-left w-50 ",
        },
        {
          key: "value",
          label: "Value",
          sortable: true,
          class: "text-left w-50 ",
        },
      ],
      itemsProvince: [],
      rows: 0,
      isProvinceBusy: false,
      take: 10,
      page: 1,
      subTotal: 0,
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getProfileComplete(),
        this.getStat(),
        // this.getTotalCustomer(),
        this.getConnectChannelChart(),
        this.getConnectChannelList(),
        this.getTotalCustomer(),
        this.getDefault(),
        this.getTableProvince(),
        this.getFilterProvince(),
      ]);
    },
    async getDefault() {
      const res = await this.$report.post(
        `/customerprofile/default`,
        this.filter
      );
      this.getAgeGroup(res.data.detail.ageGroup);
      this.getGender(res.data.detail.genderGroup);
      this.getCustomfiled();

      this.subTotal = res.data.detail.total;
    },
    async getAgeGroup(data) {
      this.ageLoading = true;

      var respData = data;
      this.chartAgeLabel = await respData.map((el) => el.group);
      this.chartAge = [
        {
          label: "Percent Of Responses",
          type: "bar",
          data: await respData.map((el) => el.percent),
          value: await respData.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
          fill: false,
        },
      ];
      this.ageLoading = false;
    },

    async getGender(data) {
      this.genderLoading = true;

      var respData = data;
      this.chartGenderLabel = await respData.map((el) => el.group);
      this.chartGender = [
        {
          label: "Percent Of Responses",
          type: "bar",
          data: await respData.map((el) => el.percent),
          value: await respData.map((el) => el.value),
          backgroundColor: await this.$store.dispatch("getChartColor", 0),
          fill: false,
        },
      ];
      this.genderLoading = false;
    },
    async getCustomfiled() {
      this.customFieldChart = [];
      this.customFieldLoading = true;
      let payload = { ...this.filter };

      const res = await this.$report.post(
        `/customerprofile/customfield`,
        payload
      );

      var respData = res.data.detail.filter((x) => x.data.length > 0);

      this.customFiedlLabel = await respData.map((field) =>
        field.data.map((item) => item.group)
      );

      var color = await this.$store.dispatch("getChartColor", 0);
      this.customFieldChart = await respData.map((field, index) => ({
        id: field.register_form_field_id,
        name: field.field_name,
        charts: [
          {
            label: "Percent of Customer",
            data: field.data.map((item) => item.percent),
            value: field.data.map((item) => item.value),
            backgroundColor: color,
          },
        ],
      }));

      this.customFieldLoading = false;
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "MM/YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date, "DD/MM/YYYY").format("DD/MM/YYYY");
      return value;
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
    async getConnectChannelChart() {
      this.chartList[0].isFetch = true;
      const res = await this.$report.post(
        `/dashboard/customer_connect/date`,
        this.filter
      );

      let label = [];
      var dataSet = [];
      let dataList = [];

      label = (await res.data.detail.map((el) => el.group_date)) || [];

      if (res.data.detail.length > 0) {
        for (let index = 0; index < res.data.detail[0].data.length; index++) {
          const element = res.data.detail[0].data[index];

          const color = await this.$store.dispatch("getChartColor", index);

          dataSet.push({
            order: index,
            label: element.channel_name,
            data: [],
            borderColor: color,
            backgroundColor: color,
            fill: false,
          });
        }

        if (this.filter.TypeDateFilter == 1) {
          dataList.map(
            (el) =>
              (el.group_date = this.handleDateFormat(
                this.filter.TypeDateFilter,
                el.group_date
              ))
          );
        }

        for (const val of dataSet) {
          let tempObj = [];

          for (const list of res.data.detail) {
            let data = list.data.find((el) => el.channel_name === val.label);
            tempObj.push(data ? data.total_member : 0);
          }

          val.data = tempObj;
        }
      }

      // let labelChart = label.map((el) =>
      //   this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      // );
      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getProfileComplete() {
      const res = await this.$report.post(
        `/dashboard/customer_connect/profile_completed`,
        this.filter
      );

      this.profileComplete = res.data.detail;
    },
    async getStat() {
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);

      this.$emit("setStat", null, date);
    },
    async getConnectChannelList() {
      this.channel.isBusy = true;
      const res = await this.$report.post(`/dashboard/customer_connect`, {
        ...this.filter,
      });

      this.channel.items = res.data.detail;
      this.channel.rows = this.channel.items.length;
      this.channel.isBusy = false;
    },
    async getTotalCustomer() {
      this.totalCustomer.isBusy = true;
      const res = await this.$report.post(
        `/dashboard/customer_connect/analysis`,
        {
          ...this.filter,
        }
      );
      let stat = {
        totalCustomer: res.data.detail.total_customer,
        percentCustomer: res.data.detail.percent_customer,
      };
      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", stat, date);
      // this.totalCustomer.items = res.data.detail;
      // this.totalCustomer.rows = this.totalCustomer.items.length;
      // this.totalCustomer.isBusy = false;
    },
    async getFilter() {
      const res = await this.$report(`/RevenueReport/CustomFilter`);

      this.options = {
        ...res.data,
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async getFilterProvince() {
      const res = await this.axios.get(
        `https://api-address-prod.d-dots.com/api/address/province`
      );

      this.options.Provinces = res.data.detail;
    },
    async getTableProvince() {
      this.isProvinceBusy = true;
      let payload = { ...this.filter };
      payload.PageNumber = this.page;
      payload.RecordPerPage = this.take;

      const res = await this.$report.post(`/customerprofile/province`, payload);
      var respData = res.data.detail;
      this.itemsProvince = respData.data;
      this.rows = res.data.detail.total;
      this.isProvinceBusy = false;
    },
    sortingChanged(ctx) {
      this.isProvinceBusy = true;
      let index = this.fieldsProvince.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filter.SortType &&
        !ctx.sortDesc &&
        this.filter.SortColumnId == index
      ) {
        this.filter.SortColumnId = 0;
        this.filter.sortBy = "";
        this.filter.sortDesc = "";
      } else {
        this.filter.SortColumnId = index;
        this.filter.SortType = ctx.sortDesc;
      }
      this.getTableProvince();
      this.isProvinceBusy = false;
    },
    paginationProduct(val) {
      this.page = val;
      this.getTableProvince();
    },
    handleChangeTakeProduct(val) {
      this.take = val;
      this.page = 1;
      this.getTableProvince();
    },
    async export(path) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/dashboard/customer_connect/${path}`,
        this.filter,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async exportType(reportType, name, customFiled_id) {
      try {
        let body = {
          ...this.filter,
          register_form_field_id: customFiled_id,
          reportType: reportType,
        };

        this.$bus.$emit("showLoading");

        const res = await this.$report.post(`/customerprofile/report`, body, {
          responseType: "blob",
        });
        let data = res;
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$bus.$emit("hideLoading");
        body = {};
      } catch (error) {
        console.error(error);
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filter.TypeDateFilter = value;
      await chart.get();
    },
    async clearFilter() {
      this.filter = {
        DateFrom: "",
        DateTo: "",
        RegisterDateFrom: "",
        RegisterDateTo: "",
        TypeDateFilter: 0, // 1 = Year , 2 = Month , 3 = Day
        LastType: 2,
        LastTypeRegister: 3,
        ChannelFilter: [], // list int
        MemberTierFilter: [], // list int
        AgeGroupFilter: [],
        GenderFilter: [],
        ProvinceFilter: [],
        PageNumber: 1,
        RecordPerPage: 10,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false, // true = ASC, flase = DESC
      };

      this.chartList.forEach((chart) => {
        if (chart.isChart) chart.filterValue = "Month";
      });
      this.$store.dispatch("clearFilter");
      this.$refs.filter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.DateFrom)
        this.filter.DateFrom = this.$moment(this.filter.DateFrom).format(
          "YYYY-MM-DD"
        );
      if (this.filter.DateTo)
        this.filter.DateTo = this.$moment(this.filter.DateTo).format(
          "YYYY-MM-DD"
        );

      this.getData();
    },
    customLabel(tooltipItem, data) {
      // if (tooltipItem.value > 0) {
      var datasetLabel =
        data.datasets[tooltipItem.datasetIndex].label || "Other";
      if (data.datasets[tooltipItem.datasetIndex].value) {
        let numData =
          data.datasets[tooltipItem.datasetIndex].value[tooltipItem.index];
        return `${datasetLabel} : ${numData} (${tooltipItem.value}%)`;
      } else {
        return `${datasetLabel} :${tooltipItem.value}`;
      }
      // }
    },
    customTitle(tooltipItem, data) {
      return `${data.labels[tooltipItem[0].index]}`;
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: underline;
}
.text-gray {
  color: whitesmoke;
}

.progress {
  height: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
  background-color: white;
  overflow: visible;
}
.progress-bar {
  position: relative;

  overflow: unset;

  background-color: var(--primary-color);
}
.progress-bar::after {
  content: "\2713";
  position: absolute;
  right: -5px;
  width: 20px;
  border: 2px solid white;
  height: 20px;
  background-color: rgb(4, 180, 4);
  border-radius: 50%;
  padding: 0.25rem;
  text-align: center;
  display: var(--display);
  align-items: center;
  justify-content: center;
}
.field-list {
  max-height: 300px;
  overflow: auto;
}
</style>
