<template>
  <div class="chart-bg">
    <SideBarFilter
      ref="revenueFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <FilterCustomDate
          customText="Transaction Date"
          id="3"
          :from="filter.TransactionDateFrom"
          :to="filter.TransactionDateTo"
          :type="filter.LastType"
          @update:type="
            (value) => {
              filter.LastType = value;
            }
          "
          @update:from="
            (value) => {
              filter.TransactionDateFrom = value;
            }
          "
          @update:to="
            (value) => {
              filter.TransactionDateTo = value;
            }
          "
        />

        <div class="my-3">
          <MultiSelect
            used="tier"
            textFloat="Member tier"
            :options="options.memberTiers"
            textField="memberTierName"
            valueField="id"
            placeholder="Select Member tier"
            v-model="filter.MemberTierFilter"
            :value="filter.MemberTierFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="channel"
            textFloat="Channel"
            :options="options.channelTypes"
            textField="channelName"
            valueField="id"
            placeholder="Select Channel"
            v-model="filter.ChannelFilter"
            :value="filter.ChannelFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="product"
            textFloat="Barcode / Product"
            :options="options.product"
            textField="productName"
            valueField="id"
            placeholder="Select Barcode / Product"
            v-model="filter.ProductFilter"
            :value="filter.ProductFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="branch"
            textFloat="Purchase Branch"
            :options="options.branches"
            textField="branchName"
            valueField="id"
            placeholder="Select Purchase Branch"
            v-model="filter.BranchFilter"
            :value="filter.BranchFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="age"
            textFloat="Age Group"
            :options="options.ageGroups"
            textField="ageGroupName"
            valueField="id"
            placeholder="Select Age Group"
            v-model="filter.AgeGroupFilter"
            :value="filter.AgeGroupFilter"
          />
        </div>
        <div class="mb-3">
          <MultiSelect
            used="gender"
            textFloat="Gender"
            :options="options.genderModels"
            textField="genderGroupName"
            valueField="id"
            placeholder="Select Gender"
            v-model="filter.GenderFilter"
            :value="filter.GenderFilter"
          />
        </div>
      </template>
    </SideBarFilter>
    <StatPanel :column="statColumn" :data="stat" :isLoading="statLoading" />

    <b-container class="mt-3">
      <b-row class="chart-gap">
        <b-col
          cols="12"
          :md="chart.name == 'Basket' ? 12 : 6"
          v-for="chart in chartList"
          :key="chart.name"
          class="resize"
        >
          <b-card
            class="shadow-sm border-none text-black chart-rounded"
            v-if="chart.name != 'Product'"
          >
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">{{ chart.name }}</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div
                    :class="`f-14 underline cursor-pointer`"
                    v-for="chartFilter in chart.filter"
                    :key="chartFilter + '-' + chart.name"
                    @click="handleChartFilter(chartFilter, chart)"
                  >
                    <span
                      :class="`${
                        chart.filterValue == chartFilter ||
                        chartFilter == 'Export'
                          ? 'text-highlight'
                          : 'text-gray'
                      }`"
                      >{{ chartFilter }}
                    </span>
                  </div>
                </div>
              </div>

              <template v-if="chart.isFetch">
                <ChartLoading :id="'loading-' + chart.name" type="line" />
              </template>
              <template v-else>
                <BasketChart
                  v-if="chart.name == 'Basket'"
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  :textRight="chart.textRight"
                  class="mt-auto"
                  :hideLegend="chart.hideLegend"
                  :label="chart.label"
                  :type="'bar'"
                  :filterValue="chart.filterValue"
                />

                <LineChart
                  :ref="`chart-${chart.export}`"
                  v-else
                  :id="chart.name"
                  :chartData="chart.data"
                  class="mt-auto"
                  :text="chart.text"
                  :label="chart.label"
                  :type="chart.chartType"
                  :chartType="chart.chartType"
                  :filterValue="chart.filterValue"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="d-flex justify-content-between">
              <div class="chart-title-name">Product</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <b-form-input
                  type="text"
                  id="header-search-bar"
                  name="search-product"
                  class="search-bar mb-2"
                  @keyup.enter="handleSearchProduct"
                  placeholder="Search Name, Barcode"
                  v-model="product.filter.search"
                  style="width: 300px"
                >
                </b-form-input>
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of ['Export']"
                  :key="chartFilter + '-' + 'product'"
                  @click="handleChartFilter('export', 'ReportProduct')"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.product"
              :items="product.items"
              :busy="product.isBusy"
              show-empty
              foot-clone
              no-footer-sorting
              empty-text="No matching records found"
              @sort-changed="sortingChanged"
              :sort-by.sync="product.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="product.filter.sortDesc"
            >
              <template v-slot:cell(totalCustomer)="data">
                <span>
                  {{ data.item.totalCustomer | numeral("0,0") }}
                  ({{
                    ((data.item.totalCustomer /
                      stat.total_product_table["totalCustomer"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(totalSales)="data">
                <span>
                  {{ data.item.totalSales | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(totalQuantity)="data">
                <span>
                  {{ data.item.totalQuantity | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(atv)="data">
                <span>
                  {{ data.item.atv | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(percenSalesContribution)="data">
                <span>
                  {{
                    ((data.item.totalSales /
                      stat.total_product_table["totalSales"]) *
                      100)
                      | numeral("0.00")
                  }}%
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_product_table[data.field.key]"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'totalCustomer' ||
                      data.field.key == 'totalQuantity'
                    "
                  >
                    {{
                      stat.total_product_table[data.field.key] | numeral("0,0")
                    }}</span
                  >
                  <span v-else>
                    {{
                      stat.total_product_table[data.field.key]
                        | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div></span
                >
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template></b-table
            >
            <Pagination
              @handleChangeTake="handleChangeTakeProduct"
              @pagination="paginationProduct"
              :filter="product.filter"
              :rows="product.rows"
              :limit="2"
            />
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black chart-rounded">
            <div class="d-flex justify-content-between mb-3">
              <div class="chart-title-name">Store</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div class="f-14 underline cursor-pointer" @click="exportStore">
                  <span class="text-highlight">Export</span>
                </div>
              </div>
            </div>
            <b-table
              fixed
              striped
              hover
              :fields="fields.store"
              :items="store.items"
              :busy="store.isBusy"
              show-empty
              empty-text="No matching records found"
              foot-clone
              no-footer-sorting
              @sort-changed="storeSorting"
              :sort-by.sync="store.filter.sortBy"
              no-local-sorting
              :sort-desc.sync="store.filter.sortDesc"
            >
              <template v-slot:cell(revenue)="data">
                <span>
                  {{ data.item.revenue | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(branchATV)="data">
                <span>
                  {{ data.item.branchATV | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(branchUPT)="data">
                <span>
                  {{ data.item.branchUPT | numeral("0,0.00") }}
                </span>
              </template>
              <template v-slot:cell(units)="data">
                <span>
                  {{ data.item.units | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(bills)="data">
                <span>
                  {{ data.item.bills | numeral("0,0") }}
                </span>
              </template>
              <template v-slot:cell(members)="data">
                <span>
                  {{ data.item.members | numeral("0,0") }}
                  ({{
                    ((data.item.members / stat.total_store_table["members"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:cell(newMembers)="data">
                <span>
                  {{ data.item.newMembers | numeral("0,0") }}
                  ({{
                    ((data.item.newMembers /
                      stat.total_store_table["newMembers"]) *
                      100)
                      | numeral("0.00")
                  }}%)
                </span>
              </template>
              <template v-slot:foot()="data">
                <span
                  v-if="stat.total_store_table.hasOwnProperty(data.field.key)"
                  class="text-center"
                >
                  <span
                    v-if="
                      data.field.key == 'units' ||
                      data.field.key == 'bills' ||
                      data.field.key == 'members' ||
                      data.field.key == 'newMembers'
                    "
                  >
                    {{
                      stat.total_store_table[data.field.key] | numeral("0,0")
                    }}
                  </span>
                  <span v-else>
                    {{
                      stat.total_store_table[data.field.key] | numeral("0,0.00")
                    }}</span
                  >
                </span>
                <span v-else>
                  <div class="text-left px-2" v-if="data.field.text">
                    {{ data.field.text }}
                  </div></span
                >
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template></b-table
            >
            <Pagination
              @handleChangeTake="handleChangeTakeStore"
              @pagination="paginationStore"
              :filter="store.filter"
              :rows="store.rows"
              class="p-2"
            /> </b-card
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import LineChart from "chart";
import ChartLoading from "@/components/loading/ChartLoading";
import BasketChart from "chart/stack";

import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { revenueMember } from "./js";
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )})`
    : null;
}
export default {
  components: {
    LineChart,
    BasketChart,
    InputDatePickerFilter,
    ChartLoading,
  },
  data() {
    return {
      filter: {
        MemberTierFilter: this.$store.state.filter.tier,
        ChannelFilter: this.$store.state.filter.channel,
        BranchFilter: this.$store.state.filter.branch,
        AgeGroupFilter: this.$store.state.filter.age,
        GenderFilter: this.$store.state.filter.gender,
        ProductFilter: this.$store.state.filter.product,

        TransactionDateFrom: this.$store.state.filter.from,
        TransactionDateTo: this.$store.state.filter.to,
        TypeDateFilter: 2,

        LastType: this.$store.state.filter.dateType,
      },

      options: {
        memberTiers: [],
        channelTypes: [],
        branches: [],
        ageGroups: [],
        genderModels: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
        product: [],
      },
      chartList: [
        {
          name: "Total Sales by date (Member Tier)",
          filterValue: "Month",
          text: "Total Sales",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportMemberTier",
          get: () => this.getRevenueMember(),
          isFetch: true,
          chartType: "line",
        },
        {
          name: "Total Sales by date (Branch Group)",
          filterValue: "Month",
          text: "Total Sales",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "ReportBranchGroup",
          get: () => this.getRevenueChannel(),
          isFetch: true,
          chartType: "line",
        },
        {
          name: "Total Customer By Date",
          filterValue: "Month",
          text: "Number Of Unique Customer",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "/uniquecustomer/report",
          get: () => this.getRevenueCustomerByDate(),
          isFetch: true,
          chartType: "bar",
        },
        {
          name: "Total Customer By Frequency",
          filterValue: "Month",
          text: "Number Of Customer",
          filter: ["Export"],
          label: [],
          data: [],
          export: "/frequencycustomer/report",
          get: () => this.getCustomerByFrequency(),
          isFetch: true,
          chartType: "bar",
        },
        {
          name: "Basket",
          filterValue: "Month",
          text: "Line : Units per Basket Average Basket Size (THB excl. VAT)",
          textRight: "Number of Order",
          filter: ["Day", "Month", "Year", "Export"],
          export: "ReportBasket",
          label: [],
          data: [],
          get: () => this.getBasket(),
          isFetch: true,
        },

        // { name: "Product", filter: ["Export"], export: "ReportProduct" },
      ],
      fields: {
        product: [
          {
            key: "barcode",
            label: "Barcode",
            class: "w-2",
            sortable: true,
            text: "Total",
          },
          {
            key: "productName",
            label: "Name",
            thClass: "w-5",
            sortable: true,
          },
          {
            key: "totalCustomer",
            label: "% Customer",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalSales",
            label: "Total Sales",
            class: "w-2",
            sortable: true,
          },
          {
            key: "totalQuantity",
            label: "Total Quantity",
            class: "w-2",
            sortable: true,
          },
          {
            key: "atv",
            label: "ATV",
            class: "w-1",
            sortable: true,
          },
          {
            key: "percenSalesContribution",
            label: "% Sales Contribution",
            class: "w-2",
            sortable: true,
          },
        ],
        store: [
          {
            key: "branchName",
            label: "Store",
            sortable: true,
            class: "w-5",
            text: "Total",
          },
          {
            key: "revenue",
            label: "Total Sales",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchATV",
            label: "ATV",
            sortable: true,
            class: "w-2",
          },
          {
            key: "branchUPT",
            label: "UPT",
            sortable: true,
            class: "w-1",
          },
          {
            key: "units",
            label: "Units",
            sortable: true,
            class: "w-1",
          },
          {
            key: "bills",
            label: "Bills",
            sortable: true,
            class: "w-1",
          },
          {
            key: "members",
            label: "Members",

            class: "w-1",
          },
          {
            key: "newMembers",
            label: "New Members",

            class: "w-2",
          },
        ],
      },
      product: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          search: "",
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      store: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          page: 1,
          take: 5,
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {
        totalCustomer: 0,
        percentCustomer: 0,
        totalBill: 0,
        totalRevenue: 0,
        totalUnit: 0,
        atv: 0,
        upt: 0,
        total_store_table: {},
        total_product_table: {},
      },
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
      statColumn: [
        {
          name: "Total Bill",
          key: "totalBill",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "Total Sales",
          key: "totalRevenue",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "Total Unit",
          key: "totalUnit",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "ATV",
          key: "atv",
          isPercent: false,
          isDecimal: true,
        },
        {
          name: "UPT",
          key: "upt",
          isPercent: false,
          isDecimal: true,
        },
      ],
      statLoading: true,
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getProductFilter();
    this.getDefault();
    this.getProduct();
    this.getStore();
  },
  methods: {
    async getData() {
      return await Promise.all([
        await this.getStat(),
        await this.getRevenueMember(),
        await this.getRevenueChannel(),
        await this.getCustomerByFrequency(),
        await this.getRevenueCustomerByDate(),
        await this.getBasket(),
        // await this.getCustomerLifeCycle(),
        await this.getProduct(),
        await this.getStore(),
      ]);
    },
    async getDefault() {
      this.statLoading = true;
      this.isBusy = true;
      this.chartList[0].isFetch = true;
      this.chartList[1].isFetch = true;
      this.chartList[2].isFetch = true;
      this.chartList[3].isFetch = true;
      this.chartList[4].isFetch = true;
      const res = await this.$report.post(
        `/RevenueReport/revenue/default`,
        this.filter
      );
      this.getBasket(true, res.data.detail.result_basket);

      this.getRevenueMember(true, res.data.detail.result_membertier);
      this.getRevenueChannel(true, res.data.detail.result_groupbranch);
      this.getCustomerByFrequency(
        true,
        res.data.detail.result_frequencyCustomer
      );
      this.getRevenueCustomerByDate(
        true,
        res.data.detail.result_uniqueCustomer
      );
      this.stat = res.data.detail.result_static;

      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.TransactionDateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.TransactionDateTo).format(
              "DD MMM YYYY"
            )}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", res.data.detail.result_static, date);

      this.statLoading = false;
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "M-YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date).format("DD/MM/YYYY");
      return value;
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
    async getRevenueMember(has, data) {
      this.chartList[0].isFetch = true;
      var respData = null;

      if (!has) {
        var res = await this.$report.post(
          `/RevenueReport/RevenueMemberTier`,
          this.filter
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }

      let { label, dataSet } = await revenueMember(
        respData,
        this.filter,
        "revenueMemberTierByFilters",
        "memberTier"
      );

      let labelChart = await label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );
      this.chartList[0].label = labelChart;

      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getRevenueChannel(has, data) {
      this.chartList[1].isFetch = true;
      var respData = null;
      if (!has) {
        var res = await this.$report.post(
          `/RevenueReport/RevenueBranchGroup`,
          this.filter
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }

      var { label, dataSet } = await revenueMember(
        respData,
        this.filter,
        "revenueGroupBranchByFilters",
        "groupBranch"
      );

      let labelChart = label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );

      this.chartList[1].label = labelChart;
      this.chartList[1].data = dataSet;
      this.chartList[1].isFetch = false;
    },
    async getRevenueCustomerByDate(has, data) {
      this.chartList[2].isFetch = true;
      var respData = null;
      if (!has) {
        var res = await this.$report.post(
          `/RevenueReport/uniquecustomer`,
          this.filter
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }

      let label = respData
        .map((el) =>
          this.handleDateFormat(this.filter.TypeDateFilter, el.filterDate)
        )
        .sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
      let color = await this.$store.dispatch("getChartColor", 0);
      var dataSet = [
        {
          label: "Unique Customer",
          data: respData.map((el) => el.uniqueCustomer),
          borderColor: color,
          backgroundColor: color,
          fill: false,
        },
      ];

      let labelChart = label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );

      this.chartList[2].label = labelChart;
      this.chartList[2].data = dataSet;
      this.chartList[2].isFetch = false;
    },
    async getBasket(has, data) {
      this.chartList[4].isFetch = true;
      var respData = null;
      if (!has) {
        var res = await this.$report.post(
          `/RevenueReport/RevenueBasket`,
          this.filter
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }

      let label = [];
      var dataSet = [];

      for (const [keys, value] of Object.entries(respData)) {
        var text = keys;
        let pointStyle = "";
        let yAxisID = "A";
        let order = 1;
        var color = "";
        if (keys == "averageBasketSize") {
          pointStyle = "triangle";
          text = "Average Basket Size";
          color = await this.$store.dispatch("getChartColor", 3);
        }
        if (keys == "unitPerBasket") {
          pointStyle = "circle";
          order = 2;
          yAxisID = "C";
          text = "Unit Per Basket";
          color = await this.$store.dispatch("getChartColor", 7);
        }
        if (keys == "numberOfOrder") {
          pointStyle = "";
          yAxisID = "B";
          text = "Number Of Order";
          order = 3;
          color = await this.$store.dispatch("getChartColor", 0);
        }
        label = [
          ...respData[keys].map((el) =>
            this.handleDateFormat(this.filter.TypeDateFilter, el.filterTime)
          ),
          ...label,
        ];

        label = [...new Set(label)].sort(function (a, b) {
          return new Date(a) - new Date(b);
        });

        dataSet.push({
          yAxisID: yAxisID,
          order: order,
          type: keys == "numberOfOrder" ? "bar" : "line",
          label: text,
          data: [
            ...label.map(
              (el) =>
                respData[keys].find(
                  (a) =>
                    this.handleDateFormat(
                      this.filter.TypeDateFilter,
                      a.filterTime
                    ) == el
                )?.data || 0
            ),
          ],
          pointStyle: pointStyle,
          borderColor: color,

          pointHoverRadius: 6,
          pointRadius: 4,
          backgroundColor: color,
          fill: false,
        });
      }

      if (label.length === 1) {
        let typeDate = "years";
        if (this.filter.TypeDateFilter === 2) typeDate = "month";
        else if (this.filter.TypeDateFilter === 3) typeDate = "days";

        let fDate = this.$moment(label[0]).subtract(1, typeDate).format();
        label = [fDate, ...label];
        dataSet.forEach((ele) => {
          ele.data.unshift(0);
        });
      }

      let labelChart = label.map((el) =>
        this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      );

      this.chartList[4].label = labelChart;
      this.chartList[4].data = dataSet;
      this.chartList[4].isFetch = false;
    },
    async getCustomerByFrequency(has, data) {
      this.chartList[3].isFetch = true;
      var respData = null;
      if (!has) {
        var res = await this.$report.post(
          `/RevenueReport/frequencycustomer`,
          this.filter
        );
        respData = res.data.detail;
      } else {
        respData = data;
      }

      let label = respData.map((el) => el.frequency);

      let color = await this.$store.dispatch("getChartColor", 0);
      var dataSet = [
        {
          label: "Number Of Customer",
          data: respData.map((el) => el.numOfCustomer),
          borderColor: color,
          borderOpacity: 1,
          backgroundColor: color,
          fill: false,
        },
      ];

      this.chartList[3].label = label;
      this.chartList[3].data = dataSet;
      this.chartList[3].isFetch = false;
    },
    async getStat() {
      this.statLoading = true;
      this.isBusy = true;
      const res = await this.$report.post(
        `/RevenueReport/RevenueStatic`,
        this.filter
      );

      this.stat = res.data.detail;

      let date =
        this.filter.LastType == 0
          ? `${this.$moment(this.filter.TransactionDateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.TransactionDateTo).format(
              "DD MMM YYYY"
            )}`
          : this.$displayFilterDate(this.filter.LastType);
      this.$emit("setStat", res.data.detail, date);
      this.statLoading = false;
    },
    async getProduct() {
      this.product.isBusy = true;
      const res = await this.$report.post(`/RevenueReport/RevenueProduct`, {
        customFilterRequest: this.filter,
        SearchProduct: this.product.filter.search,
        PageNumber: this.product.filter.page,
        RecordPerPage: this.product.filter.take,
        SortColumnId: this.product.filter.SortColumnId,
        SortType: this.product.filter.SortType, // true = ASC, false = DESC
      });

      this.product.items = res.data.detail.revenueProductResponses;
      this.product.rows = res.data.detail.totalRecord;
      this.product.isBusy = false;
    },
    async getStore() {
      this.store.isBusy = true;
      const res = await this.$report.post(`/RevenueReport/RevenueStore`, {
        customFilterRequest: this.filter,
        PageNumber: this.store.filter.page,
        RecordPerPage: this.store.filter.take,
        SortColumnId: this.store.filter.SortColumnId,
        SortType: this.store.filter.SortType,
      });

      this.store.items = res.data.detail.revenueBranchResponses;
      this.store.rows = res.data.detail.totalRecord;
      this.store.isBusy = false;
    },
    paginationProduct(val) {
      this.product.filter.page = val;
      this.getProduct();
    },
    handleChangeTakeProduct(val) {
      this.product.filter.take = val;
      this.product.filter.page = 1;
      this.getProduct();
      //   this.$emit("handleChangeTake", val);
    },
    paginationStore(val) {
      //   this.$emit("pagination", val);
      this.store.filter.page = val;
      this.getStore();
    },
    handleChangeTakeStore(val) {
      this.store.filter.take = val;
      this.store.filter.page = 1;
      this.getStore();
      //   this.$emit("handleChangeTake", val);
    },
    async getProductFilter() {
      const res = await this.$report.post(
        `/RevenueReport/CustomProductFilter`,
        {
          SearchProduct: "",
          PageNumber: 1,
          RecordPerpage: 9999,
        }
      );
      this.options = {
        ...this.options,
        product: res.data.productFilterModels.map((el) => {
          return {
            id: el.id,
            productName: `${el.barcode} / ${el.productName}`,
          };
        }),
      };
      this.$nextTick();
      this.isFilterProduct = true;
    },
    async getFilter() {
      const res = await this.axios(
        `${process.env.VUE_APP_REPORT_API}/RevenueReport/CustomFilter`
      );

      this.options = {
        ...res.data,
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async export(path) {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/RevenueReport/${path}`,
        this.filter,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async exportStore() {
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(
        `/RevenueReport/ReportStore`,
        this.filter,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "reportStore" + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filter.TypeDateFilter = value;
      await chart.get();
    },
    clearFilter() {
      this.filter = {
        MemberTierFilter: [],
        ChannelFilter: [],
        BranchFilter: [],
        AgeGroupFilter: [],
        GenderFilter: [],
        ProductFilter: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        TypeDateFilter: 2,

        LastType: 2,
      };

      this.$refs.revenueFilter.hide();
      this.filterActions();
      this.$store.dispatch("clearFilter");
    },
    filterActions() {
      if (this.filter.LastType == 1) {
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 2) {
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else if (this.filter.LastType == 3) {
        this.filter.TransactionDateFrom = this.$moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD");
        this.filter.TransactionDateTo = this.$moment().format("YYYY-MM-DD");
      } else {
        if (this.filter.TransactionDateFrom)
          this.filter.TransactionDateFrom = this.$moment(
            this.filter.TransactionDateFrom
          ).format("YYYY-MM-DD");
        if (this.filter.TransactionDateTo)
          this.filter.TransactionDateTo = this.$moment(
            this.filter.TransactionDateTo
          ).format("YYYY-MM-DD");
      }

      this.getDefault();
      this.getProduct();
      this.getStore();
    },
    handleSearchProduct() {
      this.getProduct();
    },
    sortingChanged(ctx) {
      var index = 0;
      if (ctx.sortBy == "percenSalesContribution") {
        index = this.fields.product.findIndex((el) => el.key == "totalSales");

        index = index + 1;
      } else {
        index = this.fields.product.findIndex((el) => el.key == ctx.sortBy);
        index = index + 1;
      }
      if (
        this.product.filter.SortType &&
        !ctx.sortDesc &&
        this.product.filter.SortColumnId == index
      ) {
        this.product.filter.SortColumnId = 0;
        this.product.filter.SortColumnId = 0;
        this.product.filter.sortBy = "";
        this.product.filter.sortDesc = "";
      } else {
        this.product.filter.SortColumnId = index;
        this.product.filter.SortType = ctx.sortDesc;
      }
      this.getProduct();
    },
    storeSorting(ctx) {
      let index = this.fields.store.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.store.filter.SortType &&
        !ctx.sortDesc &&
        this.store.filter.SortColumnId == index
      ) {
        this.store.filter.SortColumnId = 0;
        this.store.filter.SortColumnId = 0;
        this.store.filter.sortBy = "";
        this.store.filter.sortDesc = "";
      } else {
        this.store.filter.SortColumnId = index;
        this.store.filter.SortType = ctx.sortDesc;
      }
      this.getStore();
    },
  },
};
</script>

<style>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
</style>
