<template>
  <div>
    <div class="chart-bg">
      <div class="f-24 text-bold p-3 px-4">Realtime Customer Lifecycle</div>
      <b-container>
        <b-row class="chart-gap">
          <template v-for="items of chartList">
            <b-col cols="12" md="6" lg="6" xl="3" :key="'card' + items.name">
              <b-card
                no-body
                class="border-none shadow-sm p-3 rounded-xl h-100"
              >
                <div class="d-flex flex-column h-100">
                  <div class="chart-title">
                    <div class="chart-title-name">{{ items.name }}</div>
                    <b-button
                      type="button"
                      variant="export"
                      @click="
                        $emit('exportExcel', items.id, items.name + '-rate')
                      "
                      >Export</b-button
                    >
                  </div>
                  <div class="h-100 text-center">
                    <template v-if="isLoading">
                      <ChartLoading
                        :id="'loading-chart-pie-' + items.name.replace(' ', '')"
                        type="pie"
                      />
                    </template>
                    <PieChart
                      v-else
                      :id="'pie/' + items.name.replace(' ', '')"
                      :chartData="[
                        dashBoardRateData[items.key].value_g,
                        dashBoardRateData[items.key].value_r,
                      ]"
                      class="m-auto h-100 w-100 d-flex align-items-center justify-content-center"
                      :label="items.rate_label"
                      :percent="dashBoardRateData[items.key].percent_rate"
                      :text="items.text"
                      :tick="
                        $moment(filter.date_to).diff(filter.date_from, 'days')
                      "
                      :green_only="items.green_only"
                      :filter="`Last ${
                        dashBoardRateData[items.date] || 'x'
                      } Days`"
                    />
                  </div>
                  <div class="text-center f-20 mt-2">
                    {{ dashBoardRateData[items.rate] | numeral("0,0") }}
                    <span class="text-gray">Customers</span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </template>

          <template v-for="{ line } of chartList">
            <template v-for="item of line">
              <b-col
                cols="6"
                :key="'line-container-' + item.name"
                :set="(data = getChartData(item.data))"
              >
                <b-card no-body class="border-none shadow-sm p-3 rounded-xl">
                  <div class="chart-title">
                    <div class="chart-title-name">
                      {{
                        dashBoardNumberData[item.current_id] | numeral("0,0")
                      }}
                      {{ item.name }}
                    </div>
                    <b-button
                      type="button"
                      variant="export"
                      @click="$emit('exportExcel', item.id, item.name)"
                      >Export</b-button
                    >
                  </div>
                  <template v-if="isChartLoading">
                    <ChartLoading
                      :id="'loading-chart-' + item.name.replace(' ', '')"
                      type="line"
                    />
                  </template>
                  <template v-else>
                    <Chart
                      :id="'line/' + item.name.replace(' ', '')"
                      :chartData="data"
                      class="mt-auto mb-3 mb-lg-0"
                      :label="dashBoardLabel"
                      text="Number Of Persons"
                      :customXTicks="customTicks"
                      :customYTicks="customYTicks"
                    />
                  </template>
                </b-card>
              </b-col>
            </template>
          </template>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Chart from "chart";
import PieChart from "@/components/Chart/pie.vue";
import ChartLoading from "@/components/loading/ChartLoading";
export default {
  components: {
    Chart,
    PieChart,
    ChartLoading,
  },
  props: {
    dashBoardData: { required: true },
    dashBoardRateData: { required: true },
    dashBoardLabel: { required: true },
    dashBoardDesc: { required: true },
    filter: { required: false },
    isChartLoading: { required: false },
    isLoading: { required: false },
    dashBoardNumberData: { required: false },
  },
  data() {
    return {
      chartList: [
        {
          id: 1,
          name: "First Purchase Customer",
          key: "first_purchase_rate",
          text: "Repurchase Rate",
          rate: "current_first_purchase",
          rate_label: [
            "First Purchase to Lapse",
            "First Purchase to Active Customer",
          ],
          date: "x_date_first_purchase",
          line: [
            {
              current_id: "first_purchase",
              name: "First Purchase Customer",
              data: ["first_purchase"],
              id: [3],
            },
            {
              current_id: "first_purchase_to_lapse",
              name: "First Purchase To Lapse Customer",
              id: [10, 11],
              data: ["first_purchase_to_lapse", "first_purchase_to_active"],
            },
          ],
        },
        {
          id: 2,
          name: "Active Customer",
          key: "active_rate",
          text: "Retention Rate",
          rate: "current_active",
          date: "x_date_active",
          rate_label: ["Active Customer to Lapse", "Active Customer "],
          line: [
            {
              current_id: "active_customer",
              name: "Active Customer",
              id: [4],
              data: ["active_customer"],
            },
            {
              current_id: "active_to_lapse",
              name: "Active Customer To Lapse Customer",
              id: [12],
              data: ["active_to_lapse"],
            },
          ],
        },
        {
          id: 3,
          name: "Lapse Customer",
          key: "lapse_rate",
          text: "Win - Back",
          rate: "current_lapse",
          rate_label: [
            "Lapse Customer to Inactive Customer",
            "Lapse Customer to active Customer",
          ],
          date: "x_date_lapse",
          line: [
            {
              current_id: "lapse",
              name: "Lapse Customer",
              id: [5],
              data: ["lapse"],
            },
            {
              current_id: "lapse_to_inactive",
              name: "Lapse Customer To Inactive Customer",
              id: [13, 14],
              data: ["lapse_to_inactive", "lapse_to_active"],
            },
          ],
        },
        {
          id: 4,
          name: "Inactive Customer",
          key: "inactive_rate",
          text: "Win - Back",
          rate: "current_inactive",
          green_only: true,
          rate_label: ["Inactive Customer to Active Customer"],
          date: "x_date_iactive",
          line: [
            {
              current_id: "inactive",
              name: "Inactive Customer",
              id: [6],
              data: ["inactive_customer"],
            },
            {
              current_id: "inactive_to_active",
              name: "Inactive Customer To Active Customer",
              id: [15],
              data: ["inactive_to_active"],
            },
          ],
        },
      ],
    };
  },
  methods: {
    getChartData(key) {
      let obj = [];
      for (const a of key) {
        let result = this.dashBoardData.find((el) => el.key == a);

        obj.push(result);
      }
      return obj;
    },
    getExportId(key) {},
    customTicks(val, index) {
      return index %
        this.ChartTicked(
          this.$moment(this.filter.date_to).diff(this.filter.date_from, "days")
        ) ===
        0
        ? this.$moment(val, "DD/MM/YYYY").format("DD MMM")
        : "";
    },
    customYTicks(label) {
      function formatNumber(num, precision = 0) {
        const map = [
          { suffix: "M", threshold: 1e6, multiple: 1 },
          { suffix: "K", threshold: 1e4, multiple: 10 },
          { suffix: "K", threshold: 1e3, multiple: 1 },
          { suffix: "", threshold: 1, multiple: 1 },
        ];

        const found = map.find((x) => Math.abs(num) >= x.threshold);

        if (found) {
          if (found.threshold == 1) {
            return num.toLocaleString();
          }
          const formatted =
            (num / found.threshold) * found.multiple + found.suffix;
          return formatted;
        }

        return num;
      }

      return this.addCommas(formatNumber(label));
    },
  },
};
</script>
<style lang="scss">
.chart-desc {
  color: black;
}
.text-3xl {
  font-size: 2rem;
}
.text-6xl {
  font-size: 3rem;
}
</style>
