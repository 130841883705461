import moment from "moment";
import { store } from "@/store";
export async function revenueMember(data, filter, key, labelKey) {
  return new Promise((resolve) => {
    let label = [];
    var dataSet = [];

    data.forEach((data) => {
      label = [
        ...data[key].map((el) =>
          handleDateFormat(filter.TypeDateFilter, el.filterDate)
        ),
        ...label,
      ];
      label = [...new Set(label)].sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
    });

    data.forEach(async (item, index) => {
      let color = await store.dispatch("getChartColor", index);

      dataSet.push({
        label: item[labelKey],
        data: [
          ...label.map(
            (el) =>
              item[key].find(
                (a) =>
                  handleDateFormat(filter.TypeDateFilter, a.filterDate) == el
              )?.revenue || 0
          ),
        ],
        borderColor: color,
        backgroundColor: color,
        fill: false,
      });
    });
    if (label.length === 1) {
      let typeDate = "years";
      if (filter.TypeDateFilter === 2) typeDate = "month";
      else if (filter.TypeDateFilter === 3) typeDate = "days";

      let fDate = moment(label[0]).subtract(1, typeDate).format();
      label = [fDate, ...label];
      dataSet.forEach((ele) => {
        ele.data.unshift(0);
      });
    }
    resolve({
      dataSet,
      label,
    });
  });
}
export function handleDateFormat(type, date) {
  let value = "";
  if (type === 2) value = moment(date, "M-YYYY").format("YYYY-MM-DDT00:00:00");
  else value = moment(date).format("YYYY-MM-DDT00:00:00");
  return value;
}
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
