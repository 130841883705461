<template>
  <div class="position-relative">
    <canvas :id="id" height="250" width="auto" class="pie-chart"></canvas>
    <div class="text-center doughnut-middle-text">
      <div>{{ text }}</div>
      <div>{{ Math.round(percent) }} %</div>
      <div class="text-gray">{{ filter }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    tick: {
      required: false,
    },
    text: {
      required: false,
    },
    green_only: {
      required: false,
    },
    red_only: {
      required: false,
    },
    percent: {
      required: false,
      default: 0,
    },
    filter: {
      required: false,
      default: "Last 30 Days",
    },
  },
  data() {
    return {
      datasets: [],
    };
  },
  created() {
    if (this.green_only) {
      this.datasets = [
        {
          backgroundColor: ["#47bb57", "#ebebeb"],
          data: [this.chartData[0], 100 - this.chartData[0]],
        },
      ];
    } else if (this.red_only) {
      this.datasets = [
        {
          backgroundColor: ["#fb2b38", "#ebebeb"],
          data: [this.chartData[1], 100 - this.chartData[1]],
        },
      ];
    } else {
      this.datasets = [
        {
          backgroundColor: ["#fb2b38", "#ebebeb"],
          data: [this.chartData[1], 100 - this.chartData[1]],
        },
        {
          backgroundColor: ["#47bb57", "#ebebeb"],
          data: [this.chartData[0], 100 - this.chartData[0]],
        },
      ];
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    initChart() {
      // if(this.tick)

      var ctx = document.getElementById(this.id);

      var that = this;
      var myChart = new this.$Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.label,
          datasets: this.datasets,
        },
        options: {
          cutoutPercentage: 70,
          layout: {
            padding: {
              top: 0,
              right: 0,
            },
          },
          plugins: {
            datalabels: {
              display:
                this.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? "auto"
                  : false,
              align: "center",
              anchor: "center",
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, index, values) {
                if (index.dataIndex == 0) {
                  return value == 0 ? "" : value + "%";
                } else {
                  return "";
                }
              },
            },
          },
          maintainAspectRatio: true,
          responsive: true,
          tooltips: {
            enabled: false,
            intersect: false,
            mode: "index",
            filter: function (tooltipItem, data) {
              return tooltipItem.index == 0;
            },
            callbacks: {
              label: function (tooltipItem, data) {
                return (
                  data.labels[tooltipItem.datasetIndex] +
                  ":" +
                  data.datasets[tooltipItem.datasetIndex].data[0] +
                  "%"
                );
              },
            },
            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById("chartjs-tooltip");

              // Create element on first render
              var show = false;
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title;
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = "<thead>";

                titleLines.forEach(function (title) {
                  innerHtml += "<tr><th>" + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                bodyLines.forEach(function (body, i) {
                  show = true;
                  var colors = tooltipModel.labelColors[i];

                  var style = "background:" + colors.backgroundColor;
                  style += "; border-color:" + colors.borderColor;
                  style +=
                    "; border-width: 2px;width:10px;height: 10px;display:inline-block;margin-right:5px";
                  var span = '<div style="' + style + '"></div>';
                  innerHtml += "<tr><td>" + span + body + "</td></tr>";
                });
                innerHtml += "</tbody>";
                var tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }

              var position = this._chart.canvas.getBoundingClientRect();

              tooltipEl.style.opacity = show ? 1 : 0;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left = position.left + 25 + "px";
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
              tooltipEl.style.pointerEvents = "none";
            },
          },

          legend: {
            display: false,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
ul {
  padding-inline-start: 0px !important;
}

@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  .legend {
    bottom: 0;
    right: 50%;
    top: 100%;

    transform: translate(50%, -20px);
    ul {
      flex-direction: row !important;
      gap: 5px;
      li {
        margin-bottom: 0 !important;
      }
      span {
        font-size: 9px !important;
        width: max-content;
        max-width: 80px !important;
      }
    }
  }
}
.doughnut-middle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 85%;
}
canvas.pie-chart {
  position: relative;
  // z-index: 10;
}
#chartjs-tooltip {
  background: rgba(0, 0, 0, 0.788);
  color: white;
  z-index: 9999;
  border-radius: 5px;
}
canvas {
  margin: 0 auto !important;
}
</style>
