<template>
  <div><canvas :id="id" :height="300" width="auto"></canvas></div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    chartRange: {
      required: true,
      type: Object,
    },
    titleLeft: {
      required: false,
      type: String,
    },
    titleBottom: {
      required: false,
      type: String,
    },
    max: {
      required: false,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async initChart() {
      var ctx = document.getElementById(this.id);

      var data = {
        labels: this.chartRange.label,
        dataPercent: this.chartRange.$percent,
        datasets: [
          {
            label: "Number Of Customer",
            type: "bar",
            fill: false,
            order: 1,
            backgroundColor: await this.$store.dispatch("getChartColor", 0),
            data: this.chartRange.data.data,
            num: this.chartRange.data.dataRaw,
          },

          {
            label: "Accmulate",
            type: "line",
            fill: false,
            order: 0,
            backgroundColor:
              this.$store.state.theme.themeLogo.result.themePrimaryColor,
            borderColor:
              this.$store.state.theme.themeLogo.result.themePrimaryColor,
            data: this.chartRange.accumulate.percent,
            num: this.chartRange.accumulate.num,
          },
        ],
      };
      var that = this;
      var myChart = new this.$Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          layout: {
            padding: { top: 30, right: 0 },
          },
          plugins: {
            datalabels: {
              display:
                this.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? "auto"
                  : false,
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, index, values) {
                return value == 0 ? "" : value + "%";
              },
            },
          },

          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItem, data) {
                return `(${data.labels[tooltipItem[0].index]})`;
              },
              label: function (tooltipItem, data) {
                var datasetLabel =
                  data.datasets[tooltipItem.datasetIndex].label || "Total";

                let numData =
                  data.datasets[tooltipItem.datasetIndex].num[
                    tooltipItem.index
                  ];
                return `${datasetLabel}: ${that.addCommas(
                  numData
                )} (${that.addCommas(tooltipItem.value)}%)`;
              },
              //   footer: function (items, data) {
              //     return "Total: " + data.dataPercent[items[0].index] + "%";
              //   },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.titleBottom,
                  fontStyle: "bold",
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  fontSize: 10,
                },
              },
            ],
            yAxes: [
              {
                // type: "linear",
                // stacked: true,

                ticks: {
                  stepSize: 10,

                  min: 0,
                  max: 100,
                  fontSize: 10,
                },
                // type: "linear",
              },
            ],
          },
          // responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: "bottom",
            labels: { boxWidth: 10, boxHeight: 10 },
          },
        },
      });
    },
  },
};
</script>

<style>
canvas {
  height: 300px;
  width: 300px;
}
</style>
